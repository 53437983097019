
export class ApiService {
    static async getData(endpoint, userToken) {
        var headers = {};

        if (userToken)
        {
            headers.Authorization = userToken;
        }

        const requestOptions = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(`${endpoint}`, requestOptions);

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        return data;
    }
}