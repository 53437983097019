import {WidgetModule as widget} from "./Modules/WidgetModule";

window.addEventListener('load', function () {
    widget.Init();
});

function ReInitWidget(element)
{
    console.log('=====>> Re-Init Widget :: ' + element);
    widget.ReinitWidget(element);
}

window.ReInitWidget = ReInitWidget;