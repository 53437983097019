import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";

export class WidgetModule {

    static async Init() {
        console.log('===> Widget Module Init...');
        var elements = document.querySelectorAll('[data-component="CustomWidget"]');

        elements.forEach(element => {
            this.InitWidget(element);
        });
    }

    static async ReinitWidget(elementId){
        var element = document.getElementById(elementId);
        this.InitWidget(element);
    }

    static async InitWidget(element){
        var storedprocedure = element.getAttribute('data-sp');
        var template = element.getAttribute('data-template');
        var requireUser = false;
        var cache = element.getAttribute('data-cache');
        var host = element.getAttribute('data-host');
        var params = element.getAttribute('data-params');
        var useCalendar = false;
        var debugMode = false;

        if (element.getAttribute('data-requireUser') && element.getAttribute('data-requireUser').toLowerCase() === 'true')
        {
            requireUser = true;
        }

        if (element.getAttribute('data-debug'))
        {
            debugMode = true;
        }

        if (element.getAttribute('data-useCalendar'))
        {
            useCalendar = true;
        }

        if (!host)
        {
            console.error("Host must refer to the church prefix ONLY and cannot contain http, https, or other characters");
            return;
        }

        if (host.indexOf(".") > -1 || host.indexOf("http") > -1)
        {
            console.error("Host must refer to the church prefix ONLY and cannot contain http, https, or other characters");
            return;
        }

        if (!useCalendar)
        {            
            if (params && params.indexOf("@") < 0)
            {
                console.warn("params must include the '@' character to correctly pass parameters to the data stored procedure");
            }

            // TODO: Split on & symbol and check each for @
        }

        if (storedprocedure && useCalendar)
        {
            console.warn("Stored Procedure and Use Calendar are configured.  In this case, only UseCalendar will be processed.  Please reivew your custom widget configuration and correct to avoid this warning.");
        }

        console.info('**************************************************');
        console.info(`Element ID:       ${element.id}`);
        console.info(`Host:             ${host}`);
        console.info(`Stored Procedure: ${storedprocedure}`);
        console.info(`Params:           ${params}`);
        console.info(`Template:         ${template}`);
        console.info(`Require User:     ${requireUser}`);
        console.info(`Cache Data:       ${cache}`);
        console.info(`Use Calendar:     ${useCalendar}`);
        console.info(`Params:           ${params}`);
        console.info(`Debug Enabled:    ${debugMode}`);
        console.info('**************************************************');
        
        this.LoadWidget(element.id, storedprocedure, params, template, requireUser, cache, host, useCalendar, debugMode);

    }

    static async LoadWidget(elementId, storedprocedure, params, template, requireUser, cache, host, useCalendar, debugMode)
    {
        var element = document.getElementById(elementId);

        // Check for mpp-widgets_AuthToken localstorage
        var userData = localStorage.getItem("mpp-widgets_AuthToken");

        if (!userData)
        {
            console.info('|||===> No user is logged in.')
        }

        // Execute Client Side Auth Check
        // NOTE: Widgets doesn't remove the localStorage, but sets it to "null" on logout
        if (requireUser)
        {
            if (userData == "null" || !userData)
            {
                element.innerHTML = `<div class="alert alert-danger alert-nologin">You must be logged in to see the details of this widget.</div>`;
                return;
            }
        }

        var data = null;

        if (useCalendar)
        {
            data = await this.LoadCalendarData(params, cache, host);
        }
        else
        {
            data = await this.LoadWidgetData(userData, requireUser, storedprocedure, params, cache, host);
        }

        if (debugMode)
        {
            console.log('|||===> Data')
            console.log(data);        

        }

        // Attempt to render Widget Template
        try
        {
            var mergedTemplate = await TemplateService.GetRenderedTemplate(template, data);

            element.innerHTML = mergedTemplate;
        }
        catch (err)
        {
            console.error("Error while rendering template.");
            console.error(err);

            element.innerHTML = `<div class="alert alert-danger error">An error occurred while rendering the widget template</div>`;
        }


        // Trigger the widgetLoadedEvent
        var widgetLoadedEvent = new CustomEvent('widgetLoaded', {
            detail: `${element.id}`
        });

        window.dispatchEvent(widgetLoadedEvent);
    }       
    
    static async LoadWidgetData(userData, requireUser, storedprocedure, params, cache, host)
    {
        var url = `https://${host}.cloudapps.ministryplatform.cloud/sky/api/CustomWidget?storedProcedure=${storedprocedure}`;

        if (params)
        {
            url += `&spParams=${encodeURIComponent(this.replaceParameters(params))}`;
        }

        // Add userData to Request Parameters
        if (userData)
        {
            url += `&userData=${userData}`;
        }

        // Add cacheData to Request Parameters
        if (cache)
        {
            url += `&cacheData=${cache}`;
        }

        if (requireUser)
        {           
            // Add requireUser parameter to Request
            url += `&requireUser=true`;

            // Check UserData
            if (!userData) {
                console.error("Not logged in...");
                // Show Login Required
                element.innerHTML = `<div class="alert alert-danger error">Please login to see this widget.</div>`;
                return;
            }
        }
        
        return await api.getData(url);
    }

    static async LoadCalendarData(params, cache, host)
    {
        var url = `https://${host}.cloudapps.ministryplatform.cloud/sky/api/Events/GetEvents`;

        if (params.indexOf("?") > -1)
        {                  
            var resultString = params.replace("?", "");                        
            params = resultString;
        }

        if (params)
        {
            url += `?${params}`;
        }

        // Ensure that JSON DataFormat is enabled
        // Remove any ICAL Reference
        if (params.indexOf("&DataFormat=json") < -1)
        {
            url += `&DataFormat=json`;
            url.replace("&DataFormat=ical", "");
        }
        
        var data = await api.getData(url);

        var modelData = { "Events": data };

        return modelData;
    }    

    static replaceParameters(params)
    {
        var tParams = params;

        var re = /\[.*?]/ig
        var match;
        while ((match = re.exec(params)) != null){
            tParams = tParams.replace(match[0], this.getParameterByName(match[0]));
        }

        return tParams;
    }

    static getParameterByName(qsParam, url = window.location.href) {

        var name = qsParam.replace('[', '');
        name = name.replace(']', '');

        console.log('|||===> Getting Querystring Parameter:' + name);
        

        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';

        console.log('|||===> Param Value:' + decodeURIComponent(results[2].replace(/\+/g, ' ')));

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}